<template>
  <div class="admin-dashboard__wrapper">
    <helmet>
      <title>Admin — ChannelQuiz</title>
    </helmet>

    <breadcrumb class="px-12">
      <router-link to="/admin">Admin dashboard</router-link>
    </breadcrumb>
    <div class="admin-dashboard">
      <h2>Channels</h2>
      <el-table
        class="admin-dashboard__table"
        :data="channelsData"
        :default-sort="{prop: 'name', order: 'ascending'}"
        empty-text="No channels here yet..."
        :row-class-name="tableRowClassName">
        <el-table-column
          prop="name"
          label="Name"
          sortable
          width="200"/>
        <el-table-column
          prop="paid_until"
          label="Subscription"
          sortable
          :formatter="paidUntilFormatter"
          width="280"/>
        <el-table-column
          prop="is_disabled"
          label="Disabled"
          sortable
          :formatter="(channel) => channel.is_disabled ? 'yes' : 'no'"
          width="180"/>
        <el-table-column
          prop="paid_until"
          label="Until date"
          sortable>
          <template slot-scope="{row: channel}">
            <datepicker
              :value="new Date(channel.paid_until || Date.now())"
              @input="setPaidUntilDate(channel.cid, $event)"
              name="publish_time"
              format="dd.MM.yyyy"
              monday-first
              maximum-view="day"
              class="datepicker_position_bottom"
              input-class="datepicker-input el-input__inner"/>
          </template>
        </el-table-column>
        <el-table-column
          label="Actions"
          width="120">
          <template slot-scope="{row: channel}">
            <router-link class="button" :to="`/channel/${channel.cid}`" type="text" size="small">Open</router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import Helmet from '@/components/Helmet.vue'

  import Breadcrumb from '@/components/Breadcrumb.vue'

  import Datepicker from '@/components/Datepicker.vue'

  import subscriptionMethodsMixin from '@/mixins/subscriptionMethods'

  import store from '@/store'
  import { chain } from '@/functions/utils'
  import {
    ensureCheckAuth,
    // ensureGetChannels,
    ensureGetUser,
  } from '@/functions/data'

  import {
    apiAdminGetChannels,
    apiAdminSetPaidUntil,
  } from '@/functions/api'

  import startOfDay from 'date-fns/startOfDay'
  import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'

  export default {
    name: 'AdminDashboard',
    mixins: [
      subscriptionMethodsMixin,
    ],
    components: {
      Helmet,

      Breadcrumb,

      Datepicker,
    },
    data: () => ({
      channels: [],
    }),
    methods: {
      applyChannels(channels) {
        this.channels = channels
      },

      async setPaidUntilDate(cid, v) {
        const date = v |> startOfDay |> (_ => zonedTimeToUtc(_, {timeZone: 'Etc/GMT'}))
        const result = await apiAdminSetPaidUntil(cid, date)
        if (result.ok) {
          return this.$set(
            this.channels,
            this.channels.findIndex(v => v.cid === cid),
            result.result.channel,
          )
        }
        return this.$set(
          this.channels,
          this.channels.findIndex(v => v.cid === cid),
          this.channels.find(v => v.cid === cid),
        )
      },

      paidUntilFormatter(channel) {
        return this.getSubscriptionText(channel)
      },
      tableRowClassName({row: channel}) {
        if (this.isSubscriptionEnded(channel)) {
          return 'subscription-ended'
        }
        return ''
      },
    },
    computed: {
      channelsData() {
        return this.channels.map((channel) => ({
          ...channel,
          ...channel.paid_until && {
            paid_until: new Date(channel.paid_until),
          }
        }))
      },
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          ensureCheckAuth,
          ensureGetUser,
          // ensureGetChannels,
          ({store}) => {
            if (store.state.auth.user.is_admin) {
              return {ok: true}
            }
            return {
              ok: false,
              next: 'dashboard',
            }
          },
          async () => {
            const result = await apiAdminGetChannels()

            if (!result.ok) {
              if (result.error.code === 401) {
                return {
                  ok: false,
                  next: 'login',
                }
              }
              if (result.error.code === 403) {
                return {
                  ok: false,
                  next: 'dashboard',
                }
              }
              if (result.error.code === 503) {
                //todo
                return {
                  ok: false,
                  next: false,
                }
              }
              return {
                ok: false,
                next: vm => vm.applyError({
                  blocking: true,
                  name: 'getStats Error',
                })
              }
            }
            return {
              ok: true,
              next: (vm) => vm.applyChannels(result.result.channels)
            }
          },
        ]
      )
    },
  }
</script>

<style scoped lang="stylus">
  .admin-dashboard
    @apply px-12 pb-12
</style>

<style lang="stylus">
  .admin-dashboard
    .subscription-ended td
      background-color #fef0f0 !important

    .subscription-ended:hover td
      background-color #fde7e7 !important

  .admin-dashboard__table
    overflow visible

    .el-table__body-wrapper
      overflow visible

      .cell
        overflow visible
</style>
